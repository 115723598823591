import Axios from 'axios';
import moment from 'moment';

export default {

  data() {
    return {
      TOKEN: this.$store.state.token,
      // me: this.$store.state.me,
      hirings: [],
      proposals: [],
      notifications: [],
      banner: {
        source: '',
        author: '',
      },
      user: {
        received_reviews: [],
      },
      syncing: true,
      topOne: 0,
      reviewStatus: 1,
    }
  },

  mounted() {

    $('[data-toggle="tooltip"]').tooltip();

    // if ($('.hero-admin-clean-professional').length) {
    //   $('.conditional-row').addClass('min-h-90vh');
    // }

    feather.replace();

    if (this.$store.state.role == 'admin') this.$router.push({ path: '/dashboard/admin' });
    if (this.$store.state.role == 'client') this.$router.push({ path: '/dashboard/client' });
    if (this.$store.state.role == '') this.$router.push({ path: '/' });

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.TOKEN;

    axios
      .get('/api/v1/banners', {})
      .then(response => {
        // console.log(response.data);
        if (!response.data.error) {
          var banners = response.data.data;
          this.banner = banners[Math.floor(Math.random() * banners.length)];
        } else {
          console.log(response.data.error);
        }
      });

    axios
      .get('/api/v1/users/' + this.me.id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.user = response.data.data;
          if (this.user.specialities.length < 3) {
            $('#edit-account-modal').modal('show');
          }
        } else {
          console.log(response.data.error);
        }
      });

    axios
      .get('/api/v1/notifications?unread=1', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        this.notifications = (response.data) ? response.data.data.slice(0, 4) : [];
        this.$store.commit('setNotifications', (response.data.data || []));
      });
    axios
      .get('/api/v1/hirings?provider=' + this.me.id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        console.log(response.data.data);
        this.hirings = (response.data) ? response.data.data : [];
      });

    axios
      .get('/api/v1/proposals', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        console.log(response.data.data);
        this.proposals = (response.data) ? response.data.data : [];
        this.syncing = false;
      });

  },

  beforeDestroy() {
    $('[data-toggle="tooltip"], .tooltip').tooltip("hide");
    $('.tooltip').hide();
  },

  computed: {
    me() {
      return this.$store.state.me;
    },
    waiting() {
      // todas as contratações que eu estou participando no status 2 ou 3
      // mesmo que tenha um valor de proposta (atualização 27/11/2019)
      return this.proposals.filter(proposal => (proposal.status == 2 || proposal.status == 3));
    },
    ongoing() {
      // todas as contratações que eu fui escolhido ativas
      return this.hirings.filter(hiring => {
        let end = moment(hiring.date_end);
        let yesterday = moment().subtract(1, 'd');
        return (hiring.status == 4 && end > yesterday);
      });
    },
    approved() {
      // todas as contratações que eu fui escolhido ativas
      return this.hirings.filter(hiring => {
        return (hiring.status == 4);
      });
    },
    past() {
      let _this = this;
      // status 1, 2, 3, ou 4
      // aberto, selecão, ativo mas não cancelado ou encerrado
      return this.hirings.filter(hiring => {
        if (!hiring.provider_id) return false;
        let i = 0;
        let review = true;
        let end = moment(hiring.date_end);
        let yesterday = moment().subtract(1, 'd');
        hiring.reviews.forEach((r) => {
          if (r.user_id == _this.me.id) review = false;
        });
        return (hiring.status == 4 && end <= yesterday && review);
      });
    },
    topOneToEvaluate() {
      if (this.past.length > this.topOne) {
        return this.past[this.topOne];
      } else {
        return { id: null };
      }
    },
  },

  methods: {
    markRead(notification) {
      axios
        .put('/api/v1/notifications/' + notification.id + '/mark-read', {}, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            this.$router.push({ path: notification.url })
          } else {
            console.log(response.data.error);
          }
        });
    },
    evaluate(data) {
      var _this = this;
      _this.reviewStatus = 2;
      axios
        .post('/api/v1/reviews?hiring=' + data.hiring, data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            _this.reviewStatus = 3;
            setTimeout(() => {
              _this.topOne++;
              _this.reviewStatus = 4;
              setTimeout(() => {
                _this.reviewStatus = 1;
              }, 300);
            }, 2000);
          } else {
            console.log(response.data.error);
          }
        });
    },
  }

}
